import { useMemo, useState } from "react";
import { useGameContext } from "../../context/GameContext/useGameContext";

const chaplinWelcomeText = "Hello to Chaplin, for entering the club you have to pay a fee of 7€. By the way, I like your unicorn tatoos!"

const notEnoughMoneyText = 'You have not enough money for the entrance fee to chaplin! I think you need to find a way to earn some money'

const drinkSambucaText = "Wow, they have Sambuca at Chaplin. Perry would be so proud of us. BTW, did you know that some people think that you write sambuca with two c?! Stupid tedescos!!!"

const jonnyWantsBurgersText = "Hello Fake Italian, I am so hungry... the ukranian chicks ate all me meal prep food and no I am starving... if you can bring me burgers, I have a surprise for you!"

const noBurgersText = "You have no burgers? Please buy me some!!!"

export const useChaplinPage = () => {
  const [error, setError] = useState(null);
  const [page, setPage] = useState(null);
  const { hasBurger, money, hasPayedChaplin, addItem, removeItem, hasJonnyBurgers, setHasPayedChaplin, setHasJonnyBurgers, spendMoney } = useGameContext();

  const payChaplin = () => {
    if(money >= 7) {
        spendMoney(7)
        setHasPayedChaplin(true)
    }else{
        setError(notEnoughMoneyText)
    }
  }

  const giveJonnyBurgers = () => {
    if(hasBurger) {
        addItem('PINK_GLASSES')
        removeItem('BURGER')
        setHasJonnyBurgers(true)
    }else{
        setError(noBurgersText)
    }
  }

  const drinkSambuca = () => {
    alert(drinkSambucaText)
  }

  const searchJonny = () => {
    setPage('SEARCH_JONNY')
  }

  const text = useMemo(() => {
    if(error) return error
    if(!hasPayedChaplin) return chaplinWelcomeText
    if(page === "SEARCH_JONNY" && !hasJonnyBurgers) return jonnyWantsBurgersText
    return 'no text'
  }, [error, hasPayedChaplin, hasJonnyBurgers, page]);

  return {
    hasPayedChaplin,
    text,
    page,
    hasJonnyBurgers,
    payChaplin,
    searchJonny,
    drinkSambuca,
    giveJonnyBurgers,
  };
};
