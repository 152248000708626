import { useNavigate } from "react-router-dom";
import { Button, GamePage, Heading, Image, Text } from "../../components";
import { useGameContext } from "../../context/GameContext/useGameContext";
import wohnheimImg from "../../images/wohnheim.jpeg";

export const WohnheimPage = () => {
  const navigate = useNavigate()
  const { receiveMoney, hasCoins, setHasCoins, hasFork } = useGameContext();

  const handleLookPartyRoom = () => {
    alert(
      "Oh, I think there is a surprise party here! The birthday child looks really happy because a special person was making him such a wonderful gift he will never forget <3"
    );
  };

  const handleLookMatsRoom = () => {
    alert(
      "Puuh, its a big mess here! I think the birthday card is somewhere else!"
    );
  };

  const handleLookMaxRoom = () => {
    if(hasFork) {
      navigate('/location/max-room')
    }else{
      alert("Its locked! But a member of the glasses squat has a fork!");
    }
  };

  const handleLookAriRoom = () => {
    alert(
      "Attention! There is a wild pringles eating, kung fu panda watching italian in the room. Do not disturb her!!!"
    );
  };

  const handleLookAmelieRoom = () => {
    alert("Oh seems she is not there and the room is locked!");
  };

  const handleAskPerry = () => {
    alert("Aaagh...Drink sambuca!!!...Aaagh...Party like a 300!");
  };

  const handleLookFamousCorner = () => {
    if (!hasCoins) {
      alert(
        "No birthday card :( But because a stupid tedesco spend a lot of times in the corner and is loosing always his coins, you found 5€"
      );
      receiveMoney(5);
      setHasCoins(true);
    }else{
      alert('You already collected all the coins')
    }
  };

  return (
    <GamePage>
      <Heading>Wohnheim</Heading>
      <Image src={wohnheimImg} />
      <Text>You are at the Wohnheim. What do you want to do?</Text>
      <Button onClick={handleLookPartyRoom}>Look at the party room</Button>
      <Button onClick={handleLookMatsRoom} style={{ marginTop: 16 }}>
        Look in Mats room
      </Button>
      <Button onClick={handleLookMaxRoom} style={{ marginTop: 16 }}>
        Look in Max room
      </Button>
      <Button onClick={handleLookAriRoom} style={{ marginTop: 16 }}>
        Look in Aris room
      </Button>
      <Button onClick={handleLookAmelieRoom} style={{ marginTop: 16 }}>
        Look in Amelie room
      </Button>
      <Button onClick={handleLookFamousCorner} style={{ marginTop: 16 }}>
        Look in the famous corner
      </Button>
      <Button onClick={handleAskPerry} style={{ marginTop: 16 }}>
        Ask Perry
      </Button>
    </GamePage>
  );
};
