export const Row = ({ children, style, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};
