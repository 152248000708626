
const standardState = {
    money: 0,
    items: [],
    showItems: false,
    hasCoins: false,
    hasPayedChaplin: false,
    hasJonnyBurgers: false,
    isSolvedApacheSong: false,
}

const storedState = localStorage.getItem('gameState')

//export const defaultState = standardState
export const defaultState = storedState ? JSON.parse(storedState) : standardState

export const gameContextReducer = (state=defaultState, action) => {
    let newState = state

    const { money, items } = state

    console.log(action)

    switch (action.type) {
        case 'receive money':
            newState = {
                ...state,
                money: state.money + action.payload,
            }
            break;

        case 'spend money':
            newState = {
                ...state,
                money: money - action.payload,
            }
            break;
            
        case 'set hasPayedChaplin':
            newState = {
                ...state,
                hasPayedChaplin: action.payload
            }
            break;

        case 'set hasJonnyBurgers':
            newState = {
                ...state,
                hasJonnyBurgers: action.payload
            }
            break;

        case 'set showItems':
            newState = {
                ...state,
                showItems: action.payload
            }
            break;

        case 'add item':
            newState = {
                ...state,
                items: [...items, action.payload]
            }
            break;

        case 'remove item':
            newState = {
                ...state,
                items: items.filter(item => item !== action.payload)
            }
            break;

        case 'set isSolvedApacheSong':
            newState = {
                ...state,
                isSolvedApacheSong: action.payload
            }
            break;

        case 'set hasCoins':
            newState = {
                ...state,
                hasCoins: action.payload
            }
            break;

        case 'reset state':
            newState = {
                ...standardState
            }
            break;
        
        default:
            break;
    }

    if(action.type === 'reset state') {
        localStorage.removeItem('gameState')
    }else{
        localStorage.setItem('gameState', JSON.stringify(newState))
    }

    return newState
}