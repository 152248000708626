import { useNavigate } from "react-router-dom"
import { Button, Column, Page } from "../../components"
import { useGameContext } from "../../context/GameContext/useGameContext"

export const MenuPage = () => {

    const { resetState } = useGameContext()
    const navigate = useNavigate()

    const handleStart = () => {
        navigate('/start')
    }

    const handleReset = () => {
        alert('resetted!')
        resetState()
    }

    return (
        <Page>
            <h1 style={{ textAlign: 'center', marginTop: 40 }}>the fake italian journey</h1>
            <Column style={{ padding: 8 }}>
                <Button onClick={handleStart}>Start</Button>
                <Button onClick={handleReset} style={{ marginTop: 8 }}>Reset</Button>
            </Column>
        </Page>
    )
}