import { useNavigate } from "react-router-dom";
import { useGameContext } from "../../context/GameContext/useGameContext";
import { Button } from "../Button/Button";
import { Column } from "../Column/Column";
import { Page } from "../Page/Page";
import { Row } from "../Row/Row";
import { Text } from "../Text/Text";
import burgerImg from "../../images/burger.jpeg";
import pinkGlassesImg from "../../images/pink-glasses.jpg";
import forkImg from "../../images/fork.webp";
import { Image } from "../Image/Image";

const ITEMS = {
  BURGER: {
    name: "Burger",
    image: burgerImg,
  },
  PINK_GLASSES: {
    name: "Pink Glasses",
    image: pinkGlassesImg,
  },
  FORK: {
    name: "Fork",
    image: forkImg,
  },
};

export const GamePage = ({ children }) => {
  const navigate = useNavigate();
  const { items, money, showItems, setShowItems } = useGameContext();

  const handleShowItems = () => {
    setShowItems(true);
  };

  const handleCloseItems = () => {
    setShowItems(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Row
        style={{
          justifyContent: "space-between",
          marginTop: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
      >
        <Button size="small" color="secondary" onClick={handleGoBack}>
          Back
        </Button>
        <Row>
          <Text style={{ marginRight: 16 }}>{money}€</Text>
          <Button size="small" color="secondary" onClick={handleShowItems}>
            Items {items.length}
          </Button>
        </Row>
      </Row>
      <Page>{children}</Page>
      <Column
        style={{
          display: showItems ? "flex" : "none",
          padding: 16,
          position: "fixed",
          top: 80,
          left: 40,
          right: 40,
          bottom: 80,
          background: "white",
          boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.2)",
        }}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Text style={{ margin: 0 }}>Items</Text>
          <Button color="secondary" size="small" onClick={handleCloseItems}>
            Close
          </Button>
        </Row>
        <Column style={{ marginTop: 16 }}>
          {items.map((name, i) => {
            const item = ITEMS[name];
            return (
              <Row key={i}>
                <Image
                  src={item?.image}
                  style={{ width: 72, height: "auto", borderRadius: 5 }}
                />
                <Text style={{ marginLeft: 16 }}>{item?.name}</Text>
              </Row>
            );
          })}
        </Column>
      </Column>
    </>
  );
};
