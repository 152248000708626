export const Column = ({ children, style }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
