import { useGameContextValue } from "./useGameContextValue"
import { createContext } from "react";

export const GameContext = createContext(undefined)

export const GameContextProvider = ({ children }) => {
    const value = useGameContextValue()

    return (
        <GameContext.Provider value={value}>{children}</GameContext.Provider>
    )
}