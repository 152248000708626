import {
  Button,
  Column,
  GamePage,
  Heading,
  Image,
  Text,
} from "../../components";
import { useChaplinPage } from "./useChaplinPage";
import chaplinImg from "../../images/chaplin.jpeg";
import bouncerImg from "../../images/bouncer.png";
import jonnyImg from "../../images/jonny.jpeg";

export const ChaplinPage = () => {
  const {
    page,
    text,
    hasPayedChaplin,
    hasJonnyBurgers,
    payChaplin,
    drinkSambuca,
    searchJonny,
    giveJonnyBurgers,
  } = useChaplinPage();

  return (
    <GamePage>
      {!hasPayedChaplin ? (
        <Column>
          <Heading>At the Chaplin Door</Heading>
          <Image src={bouncerImg} />
          <Text>{text}</Text>
          <Button onClick={payChaplin}>Pay Chaplin Entrace Fee</Button>
        </Column>
      ) : page === "SEARCH_JONNY" ? (
        <Column>
          <Heading>Bouncer Jonny</Heading>
          <Image src={jonnyImg} />
          {hasJonnyBurgers ? (
            <Text>Thank you for the burger! Here are some is a cool pair of pink glasses. You will earn with those the respect of the glasses guy!</Text>
          ) : (
            <>
              <Text>{text}</Text>
              <Button onClick={giveJonnyBurgers}>Give Jonny Burgers</Button>
            </>
          )}
        </Column>
      ) : (
        <Column>
          <Heading>Inside Chaplin</Heading>
          <Image src={chaplinImg} />
          <Text>You are now in Chaplin, what do you want to do?</Text>
          <Button onClick={searchJonny}>Say hello to Jonny</Button>
          <Button onClick={drinkSambuca} style={{ marginTop: 16 }}>
            Drink a sambuca
          </Button>
        </Column>
      )}
    </GamePage>
  );
};
