export const formatDuration = (startDate, endDate) => {
  const milliseconds = endDate.getTime() - startDate.getTime();
  const days = Math.floor(milliseconds / (1000 * 3600 * 24))
  const hours = Math.floor((milliseconds - days * 1000 * 3600 * 24) / (1000 * 3600));
  const minutes = Math.floor(
    (milliseconds - days * 1000 * 3600 * 24 - hours * 1000 * 3600) / (1000 * 60)
  );
  const seconds = Math.floor(
    (milliseconds - days * 1000 * 3600 * 24 - hours * 1000 * 3600 - minutes * 1000 * 60) / 1000
  );
  return (
    (days > 9 ? days : days < 0 ? "00" : "0" + days) +
    ":" +
    (hours > 9 ? hours : hours < 0 ? "00" : "0" + hours) +
    ":" +
    (minutes > 9 ? minutes : minutes < 0 ? "00" : "0" + minutes) +
    ":" +
    (seconds > 9 ? seconds : seconds < 0 ? "00" : "0" + seconds)
  );
};
