import { useEffect } from "react";

const useInterval = (callback, duration) => {
  if (typeof callback !== "function")
    throw new Error("callback must be a function");

  if (typeof duration !== "number")
    throw new Error("duration must be a number");

  useEffect(() => {
    const intervalId = setInterval(callback, duration);
    return () => {
      clearInterval(intervalId);
    };
  }, [callback, duration]);
};

export default useInterval;
