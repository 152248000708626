import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { CountdownCheck } from './components';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
    <CountdownCheck>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CountdownCheck>
  //</React.StrictMode>
);