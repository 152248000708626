export const LaunchCounter = ({ counter }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 32,
        }}
      >
        <h1>The Fake Italian Journey</h1>
        <p>The website will go live in:</p>
        <p style={{ fontWeight: "bold" }}>{counter}</p>
      </div>
    );
  };