export const Image = ({ src, style, alt, ...props }) => {
  return (
    <img
      src={src}
      style={{ width: "100%", height: "auto", ...style }}
      alt={alt}
      {...props}
    />
  );
};
