import { useCurrentDateTime } from "../../hooks";
import { formatDuration } from "../../utils/format";
import { LaunchCounter } from "..";

const launchTime = new Date("2022-09-04T18:15:00.000");

export const CountdownCheck = ({ children }) => {
    const currentDateTime = useCurrentDateTime();

    const counter = formatDuration(currentDateTime, launchTime);
  
    const isLaunched = currentDateTime.getTime() > launchTime.getTime();

    return isLaunched ? (
        children
      ) : (
        <LaunchCounter counter={counter} />
      );
}