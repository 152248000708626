import { useState } from "react";
import {
  Button,
  GamePage,
  Heading,
  Image,
  Input,
  Row,
  Text,
} from "../../components";
import { useGameContext } from "../../context/GameContext/useGameContext";
import mcDonaldsTerminalImg from "../../images/mc-donalds-terminal.jpeg";

export const McDonaldsPage = () => {
  const { hasBurger, addItem } = useGameContext();
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    const parsedAnswer = parseInt(answer)
    if (parsedAnswer === 303 || parsedAnswer === 69) {
      addItem("BURGER");
      setError(null);
    } else {
      setError("the number is wrong!");
    }
  };

  const text = error
    ? error
    : "You have to enter the correct code to order some food: ";

  return (
    <GamePage>
      <Heading>McDonalds</Heading>
      <Image src={mcDonaldsTerminalImg} />
      {hasBurger ? (
        <Text style={{ textAlign: "center" }}>You have already a burger</Text>
      ) : (
        <>
          <Text style={{ textAlign: "center" }}>{text}</Text>
          <Row style={{ justifyContent: "center", marginBottom: 16 }}>
            <Input
              type="number"
              onChange={(e) => setAnswer(e.currentTarget.value)}
              style={{ width: 100 }}
            />
          </Row>
          <Button onClick={handleSubmit}>Submit</Button>
        </>
      )}
    </GamePage>
  );
};
