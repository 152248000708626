export const Page = ({ children, style }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: 16,
        ...style
      }}
    >
      {children}
    </div>
  );
};
