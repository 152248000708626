import { useReducer } from "react";
import { gameContextReducer, defaultState } from "./gameContextReducer";

export const useGameContextValue = () => {
  const [state, dispatch] = useReducer(gameContextReducer, defaultState);
  const { money, items } = state;

  console.log(state);

  const receiveMoney = (amount) => {
    dispatch({ type: "receive money", payload: amount });
  };

  const spendMoney = (amount) => {
    if (money >= amount) {
      dispatch({ type: "spend money", payload: amount });
    }
  };

  const setHasPayedChaplin = (state) => {
    dispatch({ type: "set hasPayedChaplin", payload: state });
  };

  const setHasJonnyBurgers = (state) => {
    dispatch({ type: "set hasJonnyBurgers", payload: state });
  };

  const setShowItems = (state) => {
    dispatch({ type: "set showItems", payload: state });
  };

  const addItem = (item) => {
    dispatch({ type: "add item", payload: item });
  };

  const removeItem = (item) => {
    dispatch({ type: "remove item", payload: item });
  };

  const setIsSolvedApacheSong = (state) => {
    dispatch({ type: "set isSolvedApacheSong", payload: state });
  };

  const setHasCoins = (state) => {
    dispatch({ type: "set hasCoins", payload: state });
  };

  const resetState = () => {
    dispatch({ type: "reset state" });
  };

  const hasBurger = items.some(item => item === 'BURGER')
  const hasPinkGlasses = items.some(item => item === 'PINK_GLASSES')
  const hasFork = items.some(item => item === 'FORK')

  return {
    ...state,
    hasBurger,
    hasPinkGlasses,
    hasFork,
    addItem,
    setHasCoins,
    removeItem,
    setShowItems,
    setIsSolvedApacheSong,
    setHasJonnyBurgers,
    receiveMoney,
    spendMoney,
    setHasPayedChaplin,
    resetState,
  };
};
