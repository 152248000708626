import { Column } from "../Column/Column";
import { Image } from "../Image/Image";
import { Text } from "../Text/Text";

export const LocationCard = ({ title, image, location, onClick }) => {
  const handleClick = () => {
    onClick(location);
  };

  return (
    <Column
      style={{
        position: "relative",
        marginBottom: 16,
        boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.2)",
      }}
    >
      <Image src={image} alt={title} title={title} onClick={handleClick}/>
      <Text
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: "center",
          background: "orange",
          padding: 8,
          margin: 0,
        }}
      >
        {title}
      </Text>
    </Column>
  );
};
