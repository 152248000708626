import { Routes, Route } from "react-router-dom";
import { GameContextProvider } from "./context/GameContext/GameContextProvider";
import { ChaplinPage } from "./pages/ChaplinPage/ChaplinPage";
import { MapPage } from "./pages/MapPage/MapPage";
import { MaxRoomPage } from "./pages/MaxRoomPage/MaxRoomPage";
import { McDonaldsPage } from "./pages/McDonaldsPage/McDonaldsPage";
import { MenuPage } from "./pages/MenuPage/MenuPage";
import { SchneckenhofPage } from "./pages/SchneckenhofPage/SchneckenhofPage";
import { StartPage } from "./pages/StartPage/StartPage";
import { WohnheimPage } from "./pages/WohnheimPage/WohnheimPage";

function App() {
  return (
    <GameContextProvider>
      <Routes>
        <Route path="/" element={<MenuPage />} />
        <Route path="/start" element={<StartPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/location">
          <Route path="/location/wohnheim" element={<WohnheimPage />} />
          <Route path="/location/chaplin" element={<ChaplinPage />} />
          <Route path="/location/mc-donalds" element={<McDonaldsPage />} />
          <Route path="/location/schneckenhof" element={<SchneckenhofPage />} />
          <Route path="/location/max-room" element={<MaxRoomPage />} />
        </Route>
      </Routes>
    </GameContextProvider>
  );
}

export default App;
