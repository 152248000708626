export const Button = ({ color = "primary", size="medium", children, style, ...props }) => {
  const backgroundColor =
    color === "primary"
      ? "orange"
      : color === "secondary"
      ? "lightblue"
      : "white";

  const padding =
    size === "large"
      ? "12px 24px"
      : size === "small"
      ? "4px 12px"
      : "8px 16px";

  const fontSize =
    size === "large"
      ? 28
      : size === "small"
      ? 20
      : 24;

  return (
    <button
      style={{
        backgroundColor,
        color: 'black',
        padding,
        border: "none",
        borderRadius: 5,
        fontSize,
        ...style,
      }}
      {...props}
    >
      {children}
    </button>
  );
};
