import { useNavigate } from "react-router-dom";
import { Button, Heading, Page, Row } from "../../components";

export const StartPage = () => {

    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate('/')
    }

    const handleContinue = () => {
        navigate('/map')
    }

  return (
    <Page>
      <Heading>Where is the birthday card?</Heading>
      <p>
        the fake italian forgot her birthday card from a stupid tedesco in Mannheim. To be able to find the card, she has to solve a lot of riddles
        and use her knowledge which she gained in the last 5 months.
      </p>
      <Row style={{ justifyContent: "space-between", marginTop: 16 }}>
        <Button color="secondary" onClick={handleGoBack} style={{ flex: 1, marginRight: 16 }}>Back</Button>
        <Button color="secondary" onClick={handleContinue} style={{ flex: 1 }}>Continue</Button>
      </Row>
    </Page>
  );
};
