import HTMLFlipBook from "react-pageflip";
import card1Img from '../../images/card-1.jpg'
import card3Img from '../../images/card-3.jpg'

export const BirthdayCard = () => {
  return (
    <HTMLFlipBook width={300} height={500}>
      <div>
        <img
          src={card1Img}
          alt="test"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <div>
        <img
          src={card3Img}
          alt="test"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </HTMLFlipBook>
  );
}