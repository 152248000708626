import { useState } from "react";
import { BirthdayCard, Button, GamePage, Heading, Input, Text } from "../../components";

export const MaxRoomPage = () => {
  const [page, setPage] = useState(null);
  const [name, setName] = useState('');

  const handleSubmit = () => {
    if(name.toLowerCase() === 'lion') {
        alert('Yes that is my name!')
        setPage('BIRTHDAY_CARD')
    }else{
        alert('No thats not my name!!!')
    }
  }

  return (
    <GamePage>
      {page === "BIRTHDAY_CARD" ? (
        <>
          <Heading>You found the card</Heading>
          <BirthdayCard />
          <Text>I am so happy that you found the card. That means that you are a real fake italian :)</Text>
        </>
      ) : (
        <>
          <Heading>Max Room</Heading>
          <Text>The stupid tedesco is in his room waiting for you. He will give you the card, when you can guess his name correct:</Text>
          <Input onChange={e => setName(e.currentTarget.value)}/>
          <Button style={{ marginTop: 16}} onClick={handleSubmit}>Submit</Button>
        </>
      )}
    </GamePage>
  );
};
