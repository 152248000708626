import { useContext } from "react";
import { GameContext } from "./GameContextProvider";

export const useGameContext = () => {
  const context = useContext(GameContext);

  if (!context) throw new Error("GameContext must be in GameContextProvider");

  return context
};
