import { useState } from "react";
import useInterval from "../useInterval/useInterval";

export const useCurrentDateTime = () => {
  const [currentDatetime, setCurrentDatetime] = useState(new Date());

  useInterval(() => {
    setCurrentDatetime(new Date());
  }, 1000);

  return currentDatetime;
};
