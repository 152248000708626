import { useEffect, useMemo, useState } from "react";
import { Button, GamePage, Heading, Image, Text } from "../../components";
import { useGameContext } from "../../context/GameContext/useGameContext";
import glassesGuyImg from "../../images/glasses-guy.jpg";
import glassesGuy2Img from "../../images/glasses-guy-2.jpg";
import schneckenhofImg from "../../images/schneckenhof.jpeg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const APACHE_LINES = [
  "Doch sie müssen los, wenn ihre Roller wieder schrein",
  "Brudi, ich muss los, wenn die Roller wieder schrein",
  "Sie machen Fotos, Taschen platzen aber Apache bleibt gleich",
  "Reden wir von Koks und von Messerstecherein",
];

const CORRECT_ORDER = ["item-2", "item-1", "item-3", "item-0"];

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightblue" : "orange",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
});

export const SchneckenhofPage = () => {
  const [page, setPage] = useState(null);
  const [lines, setLines] = useState(
    APACHE_LINES.map((content, i) => ({ id: `item-${i}`, content }))
  );
  const {
    hasPinkGlasses,
    isSolvedApacheSong,
    setIsSolvedApacheSong,
    receiveMoney,
    addItem,
  } = useGameContext();

  const handleReceiveGlasses = () => {
    addItem('FORK')
  }

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newLines = reorder(
      lines,
      result.source.index,
      result.destination.index
    );

    setLines(newLines);
  };

  const isApacheSongOrderedCorrect = useMemo(() => {
    return lines.every((line, i) => line.id === CORRECT_ORDER[i]);
  }, [lines]);

  useEffect(() => {
    if (isApacheSongOrderedCorrect && !isSolvedApacheSong) {
      alert("Correct order!");
      setIsSolvedApacheSong(true);
      receiveMoney(5);
    }
  }, [
    isApacheSongOrderedCorrect,
    isSolvedApacheSong,
    setIsSolvedApacheSong,
    receiveMoney,
  ]);

  return (
    <GamePage>
      {page === "GLASSES_SQUAT" ? (
        hasPinkGlasses ? (
          <>
            <Heading>Glasses Squat</Heading>
            <Image src={glassesGuy2Img} />
            <Text>You are now part of the glasses squat. I heard you need a fork to open a room.</Text>
            <Button onClick={handleReceiveGlasses}>Receive Fork</Button>
          </>
        ) : (
          <>
            <Heading>Schneckenhof</Heading>
            <Image src={glassesGuyImg} />
            <Text>
              Hey, why are you not wearing sun glasses at Schneckenhof? We can
              not accept you as a part of our glasses squat! come back when you
              have glasses!!!
            </Text>
          </>
        )
      ) : page === "APACHE_SONG" ? (
        <>
          <Heading>Apache Song</Heading>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {lines.map((line, index) => (
                    <Draggable
                      key={line.id}
                      draggableId={line.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {line.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Text>
            {isSolvedApacheSong
              ? "A stupid tedesco liked it so much that you can sing the song of a Mannheim artist that he is donating you 5€ for your performance"
              : "You have to bring the song lyrics in the correct order to impress a stupid tedesco"}
          </Text>
        </>
      ) : (
        <>
          <Heading>Schneckenhof</Heading>
          <Image src={schneckenhofImg} />
          <Text>You are at the Schneckenhof. What do you want to do?</Text>
          <Button onClick={() => setPage("APACHE_SONG")}>
            Sing an Apache Song
          </Button>
          <Button
            onClick={() => setPage("GLASSES_SQUAT")}
            style={{ marginTop: 16 }}
          >
            Join the glasses squat
          </Button>
        </>
      )}
    </GamePage>
  );
};
