import { GamePage, Heading, LocationCard } from "../../components";
import wohnheimImg from "../../images/wohnheim.jpeg";
import chaplinImg from "../../images/chaplin.jpeg";
import schneckenhofImg from "../../images/schneckenhof.jpeg";
import mcDonaldsImg from "../../images/mc-donalds.jpg";
import { useNavigate } from "react-router-dom";

export const MapPage = () => {
  const navigate = useNavigate();

  const handleOpenLocation = (location) => {
    navigate(`/location/${location}`);
  };

  return (
    <GamePage>
      <Heading style={{ textAlign: "center" }}>The Map</Heading>
      <LocationCard
        title="Wohnheim"
        location="wohnheim"
        image={wohnheimImg}
        onClick={handleOpenLocation}
      />
      <LocationCard
        title="Chaplin"
        location="chaplin"
        image={chaplinImg}
        onClick={handleOpenLocation}
      />
      <LocationCard
        title="Mc Donalds"
        location="mc-donalds"
        image={mcDonaldsImg}
        onClick={handleOpenLocation}
      />
      <LocationCard
        title="Schneckenhof"
        location="schneckenhof"
        image={schneckenhofImg}
        onClick={handleOpenLocation}
      />
    </GamePage>
  );
};
